.PoweredByText {
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}
.PoweredByLogo {
  height: 34px;
}
