#LoginPage {
  display: table;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to bottom, #ff4747 0%, #d00000 100%);
  margin: 0;
  padding: 0;
}

#LoginPage .operatorname {
  background: rgba(255, 255, 255, 0.3);
  /* padding: 40px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
}

#LoginPage .operatorname:hover {
  background: rgba(255, 255, 255, 0.5);
}

#LoginPage .pad {
  display: inline-grid;
  grid-template-columns: auto auto auto;
  /* padding: 10px;
  column-gap: 10px;
  row-gap: 10px; */
}

#LoginPage .pad .pad-item {
  padding: 10px;
  font-size: 30px;
  text-align: center;
  margin: 0px 10px 0px 10px;
}

#LoginPage .pad .pad-item button {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid rgba(255, 255, 255, 1);
  border-radius: 50px;
  /* padding: 10px 20px; */
  font-family: 'Montserrat';
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.46000000834465027px;
  text-align: center;
  width: 45px;
  height: 45px;
  color: white;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
}

.padItemButton{
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid rgba(255, 255, 255, 1);
    border-radius: 45px;
    font-family: 'Montserrat';
    font-size: 22px;
    font-weight: 500;
    line-height: 26px;
    text-align: center;
    width: 45px;
    height: 45px;
    color: white;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
}

#LoginPage .pad .pad-item button:hover {
  background-color: rgba(255, 255, 255, 0.3);
  cursor: pointer;
}

#LoginPage .pad .pad-item button.reverse {
  background-color: rgba(255, 255, 255, 1);
  border: 4px solid #d00000;
  color: #d00000;
  font-weight: bold;
  font-size: 32px;
}

#LoginPage .display {
  text-align: center;
  padding: 10px 0px 10px 0px;
  margin-bottom: 20px;
  position: relative;
}

#LoginPage .display .dot {
  display: inline-block;
  width: 22px;
  height: 22px;
  border-radius: 50px;
  border: 1px solid white;
  margin: auto 6px;
}

#LoginPage .display .dot.active {
  background: white;
}
