#KioskPage {
  display: table;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  color: #000;
}

#KioskPage #Header {
  /* display: table; */
  text-align: left;
  /* height: 100%;
  width: 100%; */
  margin: 0;
  padding: 0px 10px;
  background: linear-gradient(180deg, #ff4747 0%, #d00000 100%);
  color: #ffffff;
  display: flex;
  
}

#KioskPage.refund_sale #Header {
  background: linear-gradient(180deg, #87c09f 0%, #417b5a 100%);
  color: #ffffff;
}

#KioskPage #Footer {
  /* display: table; */
  /* height: 100%; */
  width: 100%;
  background-color: #333333;
  /* margin: 0;
  padding: 0; */
  color: #ffffff;
}

#KioskPage #Footer .MenuItem {
  padding: 9px 13px;
  /* text-transform: uppercase; */
  font-size: 15px;
  line-height: 26px;
  font-weight: bold;
  cursor: pointer;
  /* border-right: 1px solid white; */
}

#KioskPage #Footer .MenuItem.MenuItemOn {
  background-color: orangered;
}

#KioskPage #Footer .MenuItem:hover {
  background-color: black;
}

#KioskPage #Footer .MenuItem.MenuItemOn:hover {
  background-color: orangered;
}

#KioskPage #PanelStaffMenu {
  position: absolute;
  bottom: 0px;
  left: 0px;
  color: black;
  width: 300px;
  background: white;
  /* LC/Colour/Text+Background/White */
  background: #ffffff;
  padding-bottom: 25px;

  /* Elevation/16 */
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
    0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

#KioskPage #PanelStaffMenu .title {
  color: rgba(51, 51, 51, 0.6);
  font-size: 14px;
  font-family: 'Montserrat';
  text-align: left;
  padding: 16px;
}

#KioskPage #PanelStaffMenu .icon {
  /* color: linear-gradient(to bottom, #ff4747 0%, #d00000 100%); */
  /* filter: invert(10%) sepia(76%) saturate(6065%) hue-rotate(358deg) */
    /* brightness(100%) contrast(114%); */
  /* font-size: 17px; */
  padding: 10px 10px 10px 0px;
}

#KioskPage #PanelStaffMenu .iconColor {
  /* color: linear-gradient(to bottom, #ff4747 0%, #d00000 100%); */
  filter: invert(10%) sepia(76%) saturate(6065%) hue-rotate(358deg)
    brightness(100%) contrast(114%);
  /* font-size: 17px; */
  padding: 10px 10px 10px 0px;
}

#KioskPage #PanelStaffMenu .label {
  text-align: left;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  /* line-height: 150%; */
  padding: 10px 0px 10px 0px;

  /* identical to box height, or 26px */
  letter-spacing: 0.15px;

  /* LC/Colour/Text+Background/ Black */
  color: #333333;
  display: flex;
  align-items: center;
}

#KioskPage #PanelRecieptMenu {
  position: absolute;
  bottom: 50px;
  right: 48px;
  color: black;
  width: 300px;
  background: white;
  /* LC/Colour/Text+Background/White */
  background: #ffffff;

  /* Elevation/16 */
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
    0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

#KioskPage #PanelRecieptMenu .title {
  color: black;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: bold;
  text-align: left;
  padding: 10px;
}

#KioskPage #PanelRecieptMenu .icon {
  /* color: linear-gradient(180deg, #ff4747 0%, #d00000 100%); */
  filter: invert(10%) sepia(76%) saturate(6065%) hue-rotate(358deg)
    brightness(100%) contrast(114%);
  font-size: 17px;
  padding: 10px 10px 10px 10px;
}

#KioskPage #PanelRecieptMenu .label {
  text-align: left;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  /* line-height: 150%; */
  padding: 10px 10px 10px 10px;

  /* identical to box height, or 26px */
  letter-spacing: 0.15px;

  /* LC/Colour/Text+Background/ Black */
  color: #333333;
}

#KioskPage #PanelCalculatorMenu {
  position: absolute;
  bottom: 50px;
  right: 1px;
  color: black;
  width: 350px;
  background: white;
  /* LC/Colour/Text+Background/White */
  background: #ffffff;

  /* Elevation/16 */
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
    0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

#KioskPage #PanelCalculatorMenu .title {
  color: black;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: bold;
  text-align: left;
  padding: 10px;
}

#KioskPage .pad {
  display: inline-grid;
  grid-template-columns: auto auto auto;
  /* padding: 10px; */
  /* column-gap: 10px; */
  /* row-gap: 10px; */
}

#KioskPage .pad .pad-item {
  /* padding: 20px; */
  font-size: 30px;
  text-align: center;
}

#KioskPage .pad .pad-item button {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid silver;
  /* border: 1px solid rgba(255, 255, 255, 1); */
  /* border-radius: 50px; */
  /* padding: 10px 20px; */
  font-family: 'Montserrat';
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.46000000834465027px;
  text-align: center;
  width: 117px;
  height: 100px;
  /* padding: 20px; */
  /* color: white; */
}

#KioskPage .pad .pad-item button:hover {
  background-color: rgba(255, 255, 255, 0.3);
  cursor: pointer;
}

#KioskPage .pad .pad-item button.clear {
  background: linear-gradient(180deg, #ff4747 0%, #d00000 100%);
  color: white;
}

#KioskPage .pad .pad-item button.enter {
  background: linear-gradient(180deg, #87c09f 0%, #417b5a 100%);
  color: white;
}

/* #KioskPage .grid-container {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: space-around;
}
#KioskPage .grid-item {
  background: tomato;
  margin: 5px;
  color: white;
  font-weight: bold;
  font-size: 1.5em;
  text-align: center;
  flex: 1 0 auto;
  aspect-ratio: 1 / 1;
}

#KioskPage .grid-item {
  display: grid;
  align-items: center;
  justify-content: center;
} */

#KioskPage .grid-container {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  padding: 10px;
}

#KioskPage .grid-item {
  padding: 10px;
  text-align: center;
  aspect-ratio: 1 / 1;
}

/* @media (max-width: 1000px) {
  #KioskPage .grid-container {
    grid-template-columns: 100%;
  }
}

@media (max-width: 1200px) {
  #KioskPage .grid-container {
    grid-template-columns: 50% 50;
  }
} */

#KioskPage #SalesModeDropdown {
  border: none !important;
}

#KioskPage #SalesModeDropdown .MuiInput-root::before {
  border: none !important;
}

/* #KioskPage #DialogStudentInfo {
  width: 800px;
} */
