#ScreensaverPage {
  position: absolute;
  display: table;
  height: 100%;
  width: 100%;
  /* background-image: linear-gradient(to bottom, #ff4747 0%, #d00000 100%); */
  background: black;
  margin: 0;
  padding: 0;
  top: 0;
  z-index: 20000;
}

/* .contents {
  width: 100vw;
  height: 100vh;
}

.hooks-container {
  width: 100vw;
  height: 40rem;
  position: relative;
  border: 1px solid;
  background-color: black;
}

.hooks-element {
  width: 10rem;
}

.hooks-element svg {
  pointer-events: none;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
} */

#ScreensaverPage .ScreensaverParent {
  width: 100%;
  height: 100%;
  display: table;
  margin: 0px auto;
  vertical-align: middle;
}

#ScreensaverPage .Screensaver {
  width: 100%;
  height: 100%;
  display: table;
  margin: 0px auto;
  overflow: hidden;
}

#ScreensaverPage .ScreensaverParent img {
  width: 5rem;
  height: auto;
}
