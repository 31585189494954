#KioskPage #PanelCurrentSale .CartItemRow {
  text-align: left;
  /* width: 212px; */
  margin: 5px 5px 10px 5px;
  color: #333;
  font-family: 'Montserrat';
  font-size: 13px;
  font-weight: bold;
  padding: 5px 5px;
}

#KioskPage #PanelCurrentSale .CartItemRow:nth-child(even) {
  background: #eee;
}

#KioskPage #PanelCurrentSale .CartItemRow .Title {
  display: inline-block;
  text-align: left;
}

#KioskPage #PanelCurrentSale .CartItemRow .amount {
  color: #d00000 !important;
}

#KioskPage #PanelCurrentSale .CartItemRow .TrashIcon {
  float: right;
  margin-left: 10px;
  margin-top: 7px;
  cursor: pointer;
  color: #d00000 !important;
}

#KioskPage #PanelCurrentSale .CartItemRow .ButtonRefundItemToggle {
  float: right;
  margin-left: 0px;
  margin-top: 0px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 3px;
  padding-bottom: 3px;
  cursor: pointer;
  min-width: 0px;
  /* background: linear-gradient(180deg, #87c09f 0%, #417b5a 100%); */
}

.ButtonCancelRefund {
  float: right;
  margin-left: 0px;
  margin-top: 0px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 3px;
  padding-bottom: 3px;
  cursor: pointer;
  min-width: 0px;
  background: linear-gradient(180deg, #87c09f 0%, #417b5a 100%);
}

#KioskPage #PanelCurrentSale .CartItemRow .ButtonRefundItemToggle.Icon {
  margin-left: 10px;
  margin-top: 0px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
}
/* #KioskPage #PanelCurrentSale td.BalanceRow */
.BalanceRow {
  background: #ececec;
  /* box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 2px rgba(0, 0, 0, 0.14), 0px 0px 5px rgba(0, 0, 0, 0.12); */
  font-family: 'Montserrat';
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  color: rgba(51, 51, 51, 1);
  padding: 8px 10px 4px 11px;
  border-bottom: white 1px solid;
}
/* #KioskPage #PanelCurrentSale  */
#ButtonOrder {
  background: linear-gradient(
    180deg,
    rgba(255, 71, 71, 1) 0%,
    rgba(208, 0, 0, 1) 100%
  );
  color: #ffffff;
  /* opacity: 0.8; */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  font-family: 'Montserrat';
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: white 1px solid;
}

#KioskPage #PanelCurrentSale #ButtonOrderProcess {
  background: linear-gradient(180deg, #fcab47 0%, #cf7302 100%);
  color: #ffffff;
  /* opacity: 0.8; */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  font-family: 'Montserrat';
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 17px;
  border-bottom: white 1px solid;
}

/* #KioskPage #PanelCurrentSale */
 #ButtonOrderRefund {
  background: linear-gradient(180deg, #87c09f 0%, #417b5a 100%);
  color: #ffffff;
  /* opacity: 0.8; */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  font-family: 'Montserrat';
  font-size: 20px;
  font-weight: bold;
  /* padding: 60px 30px; */
  text-transform: uppercase;
}
