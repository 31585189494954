#DialogCustomerInfo .DialogTitle {
  text-align: center;
  font-size: 25;
  font-weight: bold;
  margin: 0px 0px 0px 0px;
  padding: 5px;
  color: white;
  background: rgba(0, 0, 0, 0)
    radial-gradient(
      36.59% 36.59% at 50% 63.41%,
      rgb(255, 71, 71) 0%,
      rgb(208, 0, 0) 100%
    )
    repeat scroll 0% 0%;
}

#DialogCustomerInfo .DialogContent {
  padding: 40px 60px 40px 60px;
}

#DialogCustomerInfo .DialogContent .Label {
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
}

#DialogCustomerInfo .DialogContent .Details {
  font-size: 16px;
  font-weight: 600;
}

#DialogCustomerInfo .DialogItemContent {
  padding: 40px 20px 40px 20px;
}

#DialogCustomerInfo .DialogItemContent .Label {
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
}

#DialogCustomerInfo .DialogItemContent .Details {
  font-size: 16px;
  font-weight: 600;
}
