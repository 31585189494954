#ManualEntryPage {
  display: table;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to bottom, #ff4747 0%, #d00000 100%);
  margin: 0;
  padding: 0;
}

#ManualEntryPage .ButtonEnter {
  background: linear-gradient(180deg, #ffd15c 0%, #ffba08 100%);
  width: 350px;
  height: 42px;
  color: black;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 0px 30px rgba(51, 51, 51, 0.2);
  border-radius: 8px;
}
