html,
body {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

body {
  font-family: 'Montserrat';
  color: white;
  margin: 0px;
  padding: 0px;
}

div#root {
  height: 100%;
  width: 100%;
}

h1 {
  padding: 20px;
}
.left,
.right {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow: hidden;
  /* padding-top: 20px; */
}
.left {
  left: 0;
  background-color: rgb(36, 0, 95);
}
.right {
  right: 0;
  background-color: rgb(56, 1, 44);
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.centered img {
  width: 150px;
  border-radius: 50%;
}

.ButtonRedOutlined {
  background: #fff !important;
  border-radius: 10px !important;
  font-weight: bold !important;
  padding: 10px 30px !important;
  border: 1px solid #d00000 !important;
  color: #d00000 !important;
}

.ButtonRedSolid {
  background: linear-gradient(180deg, #ff4747 0%, #d00000 100%) !important;
  /* box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12) !important; */
  border-radius: 10px !important;
  font-weight: bold !important;
  padding: 10px 30px !important;
}

.ButtonShutdown {
  background: linear-gradient(180deg, #ff4747 0%, #d00000 100%) !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12) !important;
  border-radius: 10px !important;
  font-weight: bold !important;
  padding: 15px 40px !important;
}
