#InitPage {
  display: table;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to bottom, #ff4747 0%, #d00000 100%);
  /* background-image: url('/images/lc-bg-pattern.svg'); */
  color: #fff;
  font-family: helvetica, arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}

.InitPageText {
  font-family: 'Montserrat';
  font-size: 50px;
  font-style: normal;
  font-weight: 800;
  line-height: 61px;
  letter-spacing: 1px;
  text-align: center;
  margin: 10px 0px 10px 0px;
}

