#DialogPayment {
  position: absolute;
  font-family: 'Montserrat';
  left: calc(50% - 300px);
  width: 600px;
}

#DialogPayment .DialogTitle {
  text-align: center;
  font-size: 35px;
  font-family: 'Montserrat';
  font-weight: bold;
  margin: 20px 0px 0px 0px;
}

#DialogPayment .DialogContentText {
  text-align: center;
  font-size: 17px;
  margin: 0px 20px;
}

#DialogPayment .DialogActions {
  justify-content: center;
  margin: 0px 0px 20px 0px;
}
#DialogPayment .ButtonCancel {
  border: 1px solid #d00000;
  color: #d00000;
  font-weight: bold;
  padding: 10px 30px;
}
/* button {
  border-radius: 10px !important;
} */
#DialogPayment .ButtonPay {
  background: linear-gradient(180deg, #ff4747 0%, #d00000 100%);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  font-weight: bold;
  padding: 10px 30px;
}
